import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import Arial from "@src/assets/fonts/Arial.woff";
import ArialBold from "@src/assets/fonts/ArialBold.woff";
import PPI from "@images/brands/ppi.png";
import FIGO from "@images/brands/figo.png";
import JonD from "@images/png/JohnD.png";
import SignatureMary from "@images/png/mary.png";
import GeneratedPDFViewer from "@petcomponents/GeneratedPDFViewer";
import OrderedList from "@petcomponents/MasterPolicyGenerator/OrderedList";

Font.register({ family: 'Arial', src: Arial });
Font.register({ family: 'ArialBold', src: ArialBold });

const MasterPolicyGenerator = ({
  product,
  groupName,
  groupNumber,
  planChoiceId,
  planId,
  coverageFromDate,
  renewalDate,
  address,
}) => {
  const productName = () => {
    return 'Accident and Illness Coverage';
  };

  const icon = () => {
    if (groupNumber.includes('GFPI')) {
      return FIGO;
    }
    return PPI;
  };

  const styles = StyleSheet.create({
    common: {
      backgroundColor: 'white',
      color: 'black',
      fontFamily: 'Arial',
      overflowWrap: 'break-word',
      padding: '50px 50px 20px 50px',
      userSelect: 'none !important',
    },
    fontLargeBold: {
      fontFamily: 'ArialBold',
      fontSize: '20px',
    },
    fontMediumBold: {
      fontFamily: 'ArialBold',
      fontSize: '12px',
    },
    fontNormal: {
      fontFamily: 'Arial',
      fontSize: '10px',
    },
    fontNormalBold: {
      fontFamily: 'ArialBold',
      fontSize: '10px',
    },
    fontXLargeBold: {
      fontFamily: 'ArialBold',
      fontSize: '24px',
    },
    positionAdminLogo: {
      width: '153px',
    },
    positionContentTitle: {
      margin: '0 0 10px 0',
      textAlign: 'center',
    },
    positionListItem: {
      margin: '0 0 0 17px',
    },
    positionPage1Header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    positionPage1HeaderRightSection: {
      textAlign: 'right',
    },
    positionPage1HeaderSection: {
      display: 'flex',
      flexDirection: 'column',
      width: '130px',
      wordWrap: 'break-word',
    },
    positionParagraph: {
      margin: '0 0 10px 0',
      textAlign: 'justify',
      textJustify: 'inter-word',
    },
    positionSecondaryTitle: {
      margin: '10px 0 10px 0',
      textAlign: 'center',
    },
    positionSignature: {
      width: '120px',
    },
    positionSignatureSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    positionSignatureSubSection: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    positionSignatureView: {
      alignItems: 'center',
      display: 'flex',
      height: '70px',
      justifyContent: 'flex-end',
      width: '150px',
    },
    positionSubTitle: {
      margin: '10px 0 10px 0',
      textAlign: 'center',
    },
    positionSummarySection: {
      margin: '10px 0 10px 0',
    },
    positionTableOfContents: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    positionTableSpacing: {
      marginTop: '20px',
    },
    positionTitle: {
      margin: '30px 0 10px 0',
      textAlign: 'center',
    },
    underLine: {
      textDecoration: 'underline',
    },
  });

  const MAAMasterPolicy = () => (
    <Document>
      <Page style={styles.common}>
        <View>
          <View style={styles.positionPage1Header}>
            <View style={styles.positionPage1HeaderSection}>
              <Text style={styles.fontNormal}>Insurer Information:</Text>
              <Text style={styles.fontNormal}>Independence American</Text>
              <Text style={styles.fontNormal}>Insurance Company</Text>
              <Text style={styles.fontNormal}>11333 N. Scottsdale Rd.,</Text>
              <Text style={styles.fontNormal}>Ste. 160</Text>
              <Text style={styles.fontNormal}>Scottsdale, AZ 85254</Text>
            </View>
            <View>
              <Image src={icon()} style={styles.positionAdminLogo} />
            </View>
            <View
              style={[
                styles.positionPage1HeaderSection,
                styles.positionPage1HeaderRightSection,
              ]}
            >
              <Text style={styles.fontNormal}>Administrator Information:</Text>
              <Text style={styles.fontNormal}>
                {get(address, 'addressLine1', '')}
              </Text>
              <Text style={styles.fontNormal}>
                {get(address, 'addressLine2', '')}
              </Text>
              <Text style={styles.fontNormal}>
                {get(address, 'city', '')}, {get(address, 'state', '')}{' '}
                {get(address, 'zipcode', '')}
              </Text>
            </View>
          </View>
          <View style={styles.positionTitle}>
            <Text style={styles.fontXLargeBold}>
              INDEPENDENCE AMERICAN INSURANCE COMPANY
            </Text>
          </View>
          <View style={styles.positionSubTitle}>
            <Text style={styles.fontMediumBold}>
              A Delaware Insurance Company
            </Text>
            <View>
              <Text style={styles.fontMediumBold}>
                Home Office:{' '}
                <Text style={styles.fontMediumBold}>
                  11333 N. Scottsdale Rd., Ste. 160, Scottsdale, AZ 85254
                </Text>
              </Text>
            </View>
          </View>
          <View style={styles.positionSecondaryTitle}>
            <Text style={styles.fontLargeBold}>Group Pet Insurance Policy</Text>
            <Text style={styles.fontLargeBold}>{productName()}</Text>
          </View>
          <View style={styles.positionSummarySection}>
            <Text style={styles.fontNormalBold}>
              GROUP POLICYHOLDER:{' '}
              <Text style={styles.fontNormal}>{groupName}</Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              GROUP NUMBER: <Text style={styles.fontNormal}>{groupNumber}</Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              MINIMUM PARTICIPATION REQUIREMENT:{' '}
              <Text style={styles.fontNormal}>
                2 Enrolled{' '}
                <Text style={styles.fontNormalBold}>Eligible Persons</Text>
              </Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              DATE OF ISSUE:{' '}
              <Text style={styles.fontNormal}>{coverageFromDate}</Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              RENEWAL DATE:{' '}
              <Text style={styles.fontNormal}>
                {`Renewals occur annually beginning ${renewalDate}`}
              </Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              STATE OF ISSUE:{' '}
              <Text style={styles.fontNormal}> Massachusetts</Text>
            </Text>
          </View>
          <View style={styles.positionParagraph}>
            <Text style={styles.fontNormal}>
              Independence American Insurance Company (hereafter referred to as{' '}
              <Text style={styles.fontNormalBold}>We, Our</Text> or{' '}
              <Text style={styles.fontNormalBold}>Us</Text>) agrees to pay group
              insurance <Text style={styles.fontNormalBold}>Coverage</Text> with
              respect to each{' '}
              <Text style={styles.fontNormalBold}>Eligible Person</Text>{' '}
              according and subject to the terms and conditions of this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>. Benefits
              are payable in United States dollars only. The{' '}
              <Text style={styles.fontNormalBold}>Coverage</Text> and insurance
              provisions approved under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text> are
              contained in the{' '}
              <Text style={styles.fontNormalBold}>Certificate</Text> of
              Insurance (hereafter referred to as{' '}
              <Text style={styles.fontNormalBold}>“Certificate”</Text>). If
              there is any conflict between the{' '}
              <Text style={styles.fontNormalBold}>Group Policy </Text>
              and the <Text style={styles.fontNormalBold}>Certificate</Text>,
              the <Text style={styles.fontNormalBold}>Group Policy</Text> will
              govern.
            </Text>
          </View>
          <View style={styles.positionParagraph}>
            <Text style={styles.fontNormal}>
              This <Text style={styles.fontNormalBold}>Group Policy</Text> is
              issued to the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> in
              consideration of the{' '}
              <Text style={styles.fontNormalBold}>Group</Text> application and
              payment of premiums, as provided herein, to take effect as of the
              date of issue. A copy of the completed{' '}
              <Text style={styles.fontNormalBold}>Group</Text> application is
              attached. This{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text> will
              terminate as described herein. Date of issue and termination will
              begin and end at 12:01 A.M. Standard Time at the address of the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>. The{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> is
              not <Text style={styles.fontNormalBold}>Our</Text> agent for any
              purpose under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>.
            </Text>
          </View>
          <View style={styles.positionParagraph}>
            <Text style={styles.fontNormal}>
              <Text style={styles.fontNormalBold}>
                This Policy includes any Group applications, certificates, and
                riders.
              </Text>{' '}
              This <Text style={styles.fontNormalBold}>Group Policy</Text> is
              issued by <Text style={styles.fontNormalBold}>Us</Text> and
              delivered in the state shown above and governed by the laws of
              that state. All terms are defined, and benefits are provided, in
              accordance with the terms, conditions and provisions of these
              documents, and applicable state laws.{' '}
            </Text>
          </View>
          <View style={styles.positionParagraph}>
            <Text style={styles.fontNormal}>
              Signed by Independence American Insurance Company
            </Text>
          </View>
          <View style={styles.positionSubTitle}>
            <Text style={styles.fontNormalBold}>
              INDEPENDENCE AMERICAN INSURANCE COMPANY
            </Text>
          </View>
          <View style={styles.positionSignatureSection}>
            <View style={styles.positionSignatureSubSection}>
              <View style={styles.positionSignatureView}>
                <Image src={JonD} style={styles.positionSignature} />
              </View>
              <Text style={styles.fontNormal}>Jon Dubauskas</Text>
              <Text style={styles.fontNormal}>President</Text>
            </View>
            <View style={styles.positionSignatureSubSection}>
              <View style={styles.positionSignatureView}>
                <Image src={SignatureMary} style={styles.positionSignature} />
              </View>
              <Text style={[styles.fontNormal]}>Mary Morandini</Text>
              <Text style={styles.fontNormal}>Secretary</Text>
            </View>
          </View>
          <View style={styles.positionSubTitle}>
            <Text style={styles.fontMediumBold}>NON-PARTICIPATING</Text>
            <Text style={styles.fontMediumBold}>
              This Policy does not share in Our profits or surplus earnings.
            </Text>
          </View>
          <View style={[styles.positionPage1Header, { marginTop: '10px' }]}>
            <View style={styles.positionPage1HeaderSection}>
              <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>1</Text>
            </View>
            <View
              style={[
                styles.positionPage1HeaderSection,
                styles.positionPage1HeaderRightSection,
              ]}
            >
              <Text style={styles.fontNormal}>012022</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page style={styles.common}>
        <View style={styles.positionTableOfContents}>
          <Text style={[styles.fontMediumBold, styles.positionTableSpacing]}>
            TABLE OF CONTENTS
          </Text>
          <Text style={[styles.fontNormal, styles.positionTableSpacing]}>
            The following appear within this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> in the
            following order:
          </Text>
          <Text style={[styles.fontNormalBold, styles.positionTableSpacing]}>
            Insurance Requirements
          </Text>
          <Text style={[styles.fontNormalBold, styles.positionTableSpacing]}>
            Policy Premiums
          </Text>
          <Text style={[styles.fontNormalBold, styles.positionTableSpacing]}>
            Termination of Group Policy
          </Text>
          <Text style={[styles.fontNormalBold, styles.positionTableSpacing]}>
            General Provisions
          </Text>
          <Text style={[styles.fontNormalBold, styles.positionTableSpacing]}>
            Certificate
          </Text>
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '530px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>2</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={styles.common}>
        <View style={styles.positionContentTitle}>
          <Text style={styles.fontMediumBold}>Insurance Requirements</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            The <Text style={styles.fontNormalBold}>Group Policyholder’s</Text>{' '}
            insurance elections are shown in the{' '}
            <Text style={styles.fontNormalBold}>Group</Text> application.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            Insurance benefits for the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> are as agreed
            upon between <Text style={styles.fontNormalBold}> Us</Text> and the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder.</Text> All
            insurance and actual benefit amounts in effect, with respect to each{' '}
            <Text style={styles.fontNormalBold}>Insured</Text>, are described in
            the <Text style={styles.fontNormalBold}>Certificate</Text>(s) issued
            by <Text style={styles.fontNormalBold}>Us</Text> to the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> for
            distribution to the{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text>, or directly to
            the <Text style={styles.fontNormalBold}>Insureds</Text>. This
            includes any applicable amendments,{' '}
            <Text style={styles.fontNormalBold}>Riders</Text> or endorsements,
            superseding or changing the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> in any way,
            and/or the essential features of insurance the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> is entitled to
            under this <Text style={styles.fontNormalBold}>Group Policy</Text>,
            and to whom the insurance benefits are payable in the event of a
            covered loss.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            The <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            will furnish all information reasonably necessary to administer this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>, including
            but not limited to the following:
          </Text>
          <View style={styles.positionListItem}>
            <View>
              <Text style={styles.fontNormal}>
                1. At least one{' '}
                <Text style={styles.fontNormalBold}>Census Report</Text> during
                each <Text style={styles.fontNormalBold}>Contract Year</Text>,
                no later than three months prior to the next insurance benefit
                renewal date.
              </Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>
                2. A list of all{' '}
                <Text style={styles.fontNormalBold}>Eligible Persons</Text> and
                documentation supporting eligibility for{' '}
                <Text style={styles.fontNormalBold}>Eligible Persons</Text>{' '}
                under the{' '}
                <Text style={styles.fontNormalBold}>Group Policy</Text>.
              </Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>
                3. Information about persons who become eligible and any amounts
                of insurance they may change and/or end.
              </Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>
                4. Any other information required to manage a{' '}
                <Text style={styles.fontNormalBold}>Claim</Text>.
              </Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>
                5. Notification of the{' '}
                <Text style={styles.fontNormalBold}>Group Policyholder’s</Text>{' '}
                change in legal status, expansion of business, dissolution,
                merger, buyout or any other significant business operational
                change.
              </Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>
                6. Notice of any additional eligible insurance classes.
              </Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>
                7. Any other information reasonably required.
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            <Text style={styles.fontNormalBold}>Census Report</Text> means a
            report providing the following information for each{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> under the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>: name,
            address and class, and name(s) of their{' '}
            <Text style={styles.fontNormalBold}>Insured Pet(s)</Text>, including
            age, weight and breed, as defined in the{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text> and the
            amount of insurance.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            <Text style={styles.fontNormalBold}>Contract Years</Text> means
            successive 12-month periods computed from the end of the initial
            rate guarantee period, or from a time agreed to in writing by the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> and{' '}
            <Text style={styles.fontNormalBold}>Us</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            The <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            must provide such information to{' '}
            <Text style={styles.fontNormalBold}>Us</Text> or{' '}
            <Text style={styles.fontNormalBold}>Our </Text>
            agents in a regular and timely manner as reasonably specified by Us
            and/or Our agents. <Text style={styles.fontNormalBold}>
              We
            </Text> or <Text style={styles.fontNormalBold}>Our</Text> agents
            have the right at all reasonable times to inspect the payroll (if
            applicable) and records of the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            relating to insurance under this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>.
          </Text>
        </View>
        <View style={[styles.positionContentTitle, { marginTop: '10px' }]}>
          <Text style={styles.fontMediumBold}>
            GROUP POLICYHOLDER RESPONSIBILITIES
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            The <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            agrees:
          </Text>
          <View>
            <Text style={styles.fontNormal}>
              1. To offer each{' '}
              <Text style={styles.fontNormalBold}>Eligible Person</Text> the
              opportunity to elect coverage under the{' '}
              <Text style={styles.fontNormalBold}>Certificate</Text> when he or
              she attains the status of{' '}
              <Text style={styles.fontNormalBold}>Employed</Text> or{' '}
              <Text style={styles.fontNormalBold}>Contracted</Text> as provided
              for in the <Text style={styles.fontNormalBold}>Certificate</Text>.
            </Text>
          </View>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            2. To furnish Independence American Insurance Company or its
            authorized <Text style={styles.fontNormalBold}>Administrator</Text>,
            on a monthly basis and on Independence American Insurance Company
            approved forms, such information as may reasonably be required by
            Independence American Insurance Company for the administration of{' '}
            <Text style={styles.fontNormalBold}>Coverage</Text> under the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>, including
            any change in an <Text style={styles.fontNormalBold}>Insured</Text>{' '}
            person’s eligibility status.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            3. To comply with all policies and procedures established by
            Independence American Insurance Company in administering and
            interpreting coverage under the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            4. To furnish all enrollment and termination change notifications to
            Independence American Insurance Company or its authorized{' '}
            <Text style={styles.fontNormalBold}>Administrator</Text> within 31
            days after the{' '}
            <Text style={styles.fontNormalBold}>Effective Date</Text> of such
            additions, changes and terminations. An{' '}
            <Text style={styles.fontNormalBold}>Eligible Person</Text> no longer
            eligible, as defined, must be terminated on the premium due date
            coinciding with the date on which the{' '}
            <Text style={styles.fontNormalBold}>Eligible Person</Text> ceases to
            qualify as an{' '}
            <Text style={styles.fontNormalBold}>Eligible Person</Text>. If the
            <Text style={styles.fontNormalBold}> Group Policyholder</Text> fails
            to send notification to{' '}
            <Text style={styles.fontNormalBold}>Us</Text> of any ineligible
            insured’s termination within 31 days of the date the person ceases
            to be an <Text style={styles.fontNormalBold}>Eligible Person</Text>,
            the <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            shall forfeit any premium refund/credit that would otherwise have
            been due.
          </Text>
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '100px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>3</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View style={styles.positionParagraph}>
          <View style={styles.positionContentTitle}>
            <Text style={styles.fontMediumBold}>Policy Premium</Text>
          </View>
          <View>
            <Text style={styles.fontNormalBold}>Payment of Premiums</Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              1. Premiums are due on the first of the month for which the
              premium applies, unless otherwise approved by{' '}
              <Text style={styles.fontNormalBold}>Us</Text>.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              2. The premium due on each premium due date is the sum of the
              premiums for all{' '}
              <Text style={styles.fontNormalBold}>Insureds </Text>under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              3. The{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
              determines the amount, if any, of each{' '}
              <Text style={styles.fontNormalBold}>Insured’s</Text> contribution
              toward the cost of insurance.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              4. Each premium is payable on or before its premium due date
              directly to <Text style={styles.fontNormalBold}>Us</Text> or{' '}
              <Text style={styles.fontNormalBold}>Our</Text> legal
              representative.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              5. Premium is due for an{' '}
              <Text style={styles.fontNormalBold}>Insured</Text> for each month
              the <Text style={styles.fontNormalBold}>Insured</Text> is insured
              under this <Text style={styles.fontNormalBold}>Group Policy</Text>
              . The{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> must
              notify <Text style={styles.fontNormalBold}>Us</Text> immediately
              whenever someone becomes eligible or ceases to be eligible for
              insurance.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              6. All premiums are based upon information provided in the{' '}
              <Text style={styles.fontNormalBold}>Census Reports</Text>.
            </Text>
          </View>
        </View>
        <View style={styles.positionParagraph}>
          <View>
            <Text style={styles.fontNormalBold}>Changes in Premium Rates</Text>
          </View>
          <View>
            <Text>
              <Text style={styles.fontNormalBold}>
                Special Circumstances. We
              </Text>{' '}
              may change premium rates, to be effective on the next premium due
              date, if any of the following occur:
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              1. A change or clarification in a law or governmental regulation
              affects the amount payable under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>. Any
              change in premium rates will reflect only the change in{' '}
              <Text style={styles.fontNormalBold}>Our</Text> obligations.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              2. One or more changes occur in the factors material to the
              underwriting risk <Text style={styles.fontNormalBold}>We</Text>{' '}
              assumed with respect to the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>,
              including, but not limited to, changes to the location of the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> the
              number of <Text style={styles.fontNormalBold}>Pet(s)</Text>{' '}
              insured, age, and breed classification.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              3. The premium contribution arrangement for{' '}
              <Text style={styles.fontNormalBold}>Certificate Holder</Text>{' '}
              changes or varies by the{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text> provisions
              when issued or last renewed.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              4. The{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
              requests an insurance benefit design change.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              5. <Text style={styles.fontNormalBold}>We</Text>, and the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>,
              mutually agree to change premium rates.
            </Text>
          </View>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            In all other cases, and subject to a period{' '}
            <Text style={styles.fontNormalBold}>We</Text> have provided the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> with a
            written rate guarantee,{' '}
            <Text style={styles.fontNormalBold}>We</Text> may change premium
            rates upon 60 days advance written notice to the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text>. Any
            change in premium rates may be made effective on any premium due
            date, but no change will be made more than once in any{' '}
            <Text style={styles.fontNormalBold}>Contract Year</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <View>
            <Text style={styles.fontNormalBold}>Premium Adjustments</Text>
          </View>
          <View>
            <Text>
              Premium adjustments involving a return of unearned premiums to a{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> may
              be limited to the 12 months just before the date{' '}
              <Text style={styles.fontNormalBold}>We</Text> receive a request
              for premium adjustment.
            </Text>
          </View>
        </View>
        <View style={styles.positionParagraph}>
          <View>
            <Text style={styles.fontNormalBold}>
              Grace Period, Termination for Nonpayment and Reinstatement
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              1. If a premium is not paid on or before its premium due date, it
              may be paid during the grace period. The insurance will remain in
              force during the grace period.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              2. Grace period means the [60] days following the premium due
              date.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              3. If the premium is not paid during the grace period, insurance
              under this <Text style={styles.fontNormalBold}>Group Policy</Text>{' '}
              will terminate automatically at the end of the grace period.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              4. The{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> is
              liable for the payment of a pro rata premium, if applicable,
              during the grace period.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              5. If any premium is not paid and insurance is terminated, the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> may
              request a reinstatement by reapplying and submitting the required
              premium. Insurance will be reinstated upon{' '}
              <Text style={styles.fontNormalBold}>Our</Text> approval, if the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
              requests reinstatement within 31 days of the termination date.{' '}
              <Text style={styles.fontNormalBold}>We</Text> shall approve or
              disapprove the reinstatement within 15 calendar days following
              receipt of the reinstatement request and premium.{' '}
              <Text style={styles.fontNormalBold}>We</Text> and the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> have
              the same rights as <Text style={styles.fontNormalBold}>We</Text>{' '}
              did under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>{' '}
              immediately before the due date of the defaulted premium, subject
              to any provisions in connection with the reinstatement.
            </Text>
          </View>
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '150px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>4</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View style={styles.positionParagraph}>
          <View style={styles.positionContentTitle}>
            <Text style={styles.fontMediumBold}>
              Termination of Group Policy
            </Text>
          </View>
          <View>
            <Text>
              <Text style={styles.fontNormalBold}> By Us: </Text>We can end this
              <Text style={styles.fontNormalBold}> Group Policy : </Text>
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              1. After the first anniversary (of the effective) date, or earlier
              if due to nonpayment of premium or the failure to meet continued
              underwriting guidelines, if any.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              2. On any premium due date if the number of
              <Text style={styles.fontNormalBold}> Insureds </Text>is less than
              the Minimum Participation Requirements
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              3. On any premium due date if
              <Text style={styles.fontNormalBold}> We </Text>determine the
              <Text style={styles.fontNormalBold}> Group Policyholder </Text>
              has failed to promptly furnish any necessary information requested
              or has failed to perform any other obligations relating to this
              <Text style={styles.fontNormalBold}> Group Policy </Text>
              or insurance under this
              <Text style={styles.fontNormalBold}> Group Policyholder. </Text>
            </Text>
          </View>
        </View>
        <View style={styles.positionListItem}>
          <Text>
            4. On the date the
            <Text style={styles.fontNormalBold}> Group Policyholder </Text>
            breaches any part of the Entire Contract.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            Any termination by <Text style={styles.fontNormalBold}>Us</Text> is
            without prejudice to any expenses originating prior to the
            termination date. An expense will be considered incurred on the date
            of the occurrence.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>
              By the Group Policyholder:
            </Text>{' '}
            The <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            can end this <Text style={styles.fontNormalBold}>Group Policy</Text>{' '}
            by giving <Text style={styles.fontNormalBold}>Us</Text> a 60-day
            advance written notice.
          </Text>
        </View>
        <View style={[styles.positionContentTitle, { marginTop: '25px' }]}>
          <Text style={styles.fontMediumBold}>General Provisions</Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Certificates</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>We</Text> will prepare the{' '}
            <Text style={styles.fontNormalBold}>Certificates</Text> setting
            forth the main features of this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> applicable
            to each <Text style={styles.fontNormalBold}>Insured.</Text>{' '}
            <Text style={styles.fontNormalBold}>We</Text> and the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> may
            agree to distribute the{' '}
            <Text style={styles.fontNormalBold}>Certificates</Text> to{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text> in paper format,
            or to make the document available and accessible for review by{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text> on the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder’s</Text>{' '}
            website. The{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> will
            be responsible for providing sufficient notice to the{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text> of the existence
            and availability of the{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text>, including
            instructions on how to view the document, and a statement that a
            paper copy is available upon request. Upon receiving such a request
            from either the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> or{' '}
            <Text style={styles.fontNormalBold}>Insured</Text>,{' '}
            <Text style={styles.fontNormalBold}>We</Text> will provide a written
            copy of the <Text style={styles.fontNormalBold}>Certificate</Text>{' '}
            to the <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            for distribution to the Insured. If the terms of the{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text> differ from
            the terms of insurance under this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>, the latter
            will govern.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Clerical Error</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            A clerical error may be made by{' '}
            <Text style={styles.fontNormalBold}>Us</Text> or the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> in
            keeping data. If so, when the error is found the premium and/or
            benefits will be adjusted according to the correct data. An error
            will not end insurance validly in force, nor will it continue
            insurance validly ended.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>
            Conformity with State and Federal Laws
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            If any provisions of <Text style={styles.fontNormalBold}>Our</Text>{' '}
            forms are contrary to any law to which it is subject, such provision
            is hereby amended to conform to the minimum requirements of such
            law.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Entire Contract, Changes</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            This <Text style={styles.fontNormalBold}>Group Policy</Text>,
            including any applications,{' '}
            <Text style={styles.fontNormalBold}>
              Certificates, Declarations Pages, Riders,{' '}
            </Text>
            or endorsements constitutes the entire contract of Insurance. No
            change in this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> shall be
            valid until approved by one of our executive officers and unless
            such approval is endorsed hereon or attached hereto. No agent has
            authority to change this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> or waive any
            of its provisions. <Text style={styles.fontNormalBold}>We</Text>{' '}
            have the right to determine all questions arising in connection with
            this <Text style={styles.fontNormalBold}>Group Policy</Text>,
            including its interpretation.{' '}
            <Text style={styles.fontNormalBold}>Our</Text> failure to enforce
            any provision shall not waive, modify or render such provision
            unenforceable at any other time; at any given time; or under any
            given set of circumstances, whether the circumstances are or are not
            the same.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Legal Actions</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            No action shall be brought to recover on this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> prior to the
            expiration of 60 days after written notice and the expiration of 3
            years after written application for insurance.
          </Text>
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '100px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>5</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View>
          <Text style={styles.fontNormalBold}>Misstatement</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            If premiums for a <Text style={styles.fontNormalBold}>Pet</Text> are
            based on enrollment information and the{' '}
            <Text style={styles.fontNormalBold}>Pet</Text> enrollment
            information has been misstated, there will be a fair adjustment of
            premiums based on his or her correct information. If the benefits
            for the <Text style={styles.fontNormalBold}>Pet</Text> are based on
            the enrollment information which has been misstated, there will be
            an adjustment of said benefit based on the{' '}
            <Text style={styles.fontNormalBold}>Pet</Text> correct information.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>
            Time Limit on Certain Defenses
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            All statements made in the{' '}
            <Text style={styles.fontNormalBold}>Group</Text> application are, in
            the absence of fraud, representations and not warranties. We cannot
            contest this <Text style={styles.fontNormalBold}>Policy</Text> after
            it has been in force for 2 years from its date of issue. No
            statement shall be used to contest the validity of insurance or
            reduce benefits, unless it is in writing, signed by the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text>, and a
            copy of the statement is furnished to the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder.</Text>
          </Text>
        </View>
        <View style={[styles.positionContentTitle, { marginTop: '15px' }]}>
          <Text style={styles.fontMediumBold}>Definitions</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Accident</Text> means a sudden
            and unpreventable event that causes physical{' '}
            <Text style={styles.fontNormalBold}>Injury</Text> to an{' '}
            <Text style={styles.fontNormalBold}>Insureds Pet(s)</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Administrator</Text> means the
            third-party <Text style={styles.fontNormalBold}>Administrator</Text>{' '}
            contracted to provide administrative services for Independence
            American Insurance Company. The{' '}
            <Text style={styles.fontNormalBold}>Administrator</Text> is noted on
            the cover page of the{' '}
            <Text style={styles.fontNormalBold}>Group Policy, Certificate</Text>{' '}
            and the <Text style={styles.fontNormalBold}>Declarations Page</Text>
            .
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Annual Enrollment Period</Text>{' '}
            means a period pre-determined by the{' '}
            <Text style={styles.fontNormalBold}>Insureds Pet</Text> on an annual
            basis, limited to one annual enrollment in a 12-month period.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Annual Limit </Text>means the
            maximum amount <Text style={styles.fontNormalBold}>We</Text> will
            reimburse each <Text style={styles.fontNormalBold}>Insured</Text>{' '}
            for all <Text style={styles.fontNormalBold}>Covered Expenses</Text>{' '}
            during a <Text style={styles.fontNormalBold}>Coverage Period</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Benefit Waiting Period</Text>{' '}
            means the time period (as shown in the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text>) each{' '}
            <Text style={styles.fontNormalBold}>Pet</Text> must wait before{' '}
            <Text style={styles.fontNormalBold}>Coverage</Text> is payable. The{' '}
            <Text style={styles.fontNormalBold}>Benefit Waiting Period</Text>{' '}
            starts from the{' '}
            <Text style={styles.fontNormalBold}>Effective Date</Text> of{' '}
            <Text style={styles.fontNormalBold}>Coverage</Text>. Conditions that
            occur during the{' '}
            <Text style={styles.fontNormalBold}>Benefit Waiting Period</Text>{' '}
            will be excluded from{' '}
            <Text style={styles.fontNormalBold}>Coverage</Text> as{' '}
            <Text style={styles.fontNormalBold}>Pre-existing Conditions</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Bilateral Condition</Text> means
            a condition or disease that affects both sides of the body.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Certificate</Text> means the
            contract providing the benefits described herein issued to the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text>, which
            constitutes evidence of the{' '}
            <Text style={styles.fontNormalBold}>Insureds Coverage</Text> under
            the <Text style={styles.fontNormalBold}>Group Policy</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Certificate Holder</Text> means
            the <Text style={styles.fontNormalBold}>Eligible Person</Text> the{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text> is issued to
            as indicated on the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Claim</Text> means an{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text> request for
            payment of an amount under the terms of the{' '}
            <Text style={styles.fontNormalBold}>Insureds Certificate</Text> for{' '}
            <Text style={styles.fontNormalBold}>Treatment</Text> of their{' '}
            <Text style={styles.fontNormalBold}>Pet</Text> by a{' '}
            <Text style={styles.fontNormalBold}>Veterinary Provider</Text> or
            other services as provided by their{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Clinical Signs</Text> means
            changes in the normal healthy state, bodily function, or behavior of
            an <Text style={styles.fontNormalBold}>Insureds Pet</Text> observed
            by the <Text style={styles.fontNormalBold}>Insured</Text>, a{' '}
            <Text style={styles.fontNormalBold}>Veterinarian</Text>, or other
            observer.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Coinsurance</Text> means the{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text> portion of{' '}
            <Text style={styles.fontNormalBold}>Covered Expenses</Text> after
            the <Text style={styles.fontNormalBold}>Deductible</Text> is met per{' '}
            <Text style={styles.fontNormalBold}>Pet</Text>. An{' '}
            <Text style={styles.fontNormalBold}>Insureds Coinsurance</Text>{' '}
            amount is shown on their{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Congenital</Text> means an{' '}
            <Text style={styles.fontNormalBold}>Illness</Text>, disease or
            condition that was present at or dated from the birth of{' '}
            <Text style={styles.fontNormalBold}>Your Pet(s)</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Contracted</Text> means a person
            retained for a service, task or project by the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text>,
            related to the Group Policyholder’s business, for a predetermined
            time, purpose, and for a predetermined price.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Contributory</Text> means the
            Insured pays all or a portion of their insurance premium.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Coverage</Text> means the
            insurance described in the{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text> and
            applicable <Text style={styles.fontNormalBold}>Rider(s)</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Covered Expenses</Text> means
            the{' '}
            <Text style={styles.fontNormalBold}>Reasonable and Customary</Text>{' '}
            charges for{' '}
            <Text style={styles.fontNormalBold}>
              Medically Necessary Treatments
            </Text>{' '}
            for an <Text style={styles.fontNormalBold}>Accident</Text> or{' '}
            <Text style={styles.fontNormalBold}>Illness</Text> provided by an{' '}
            <Text style={styles.fontNormalBold}>Insureds Veterinarian</Text>{' '}
            during the{' '}
            <Text style={styles.fontNormalBold}>Coverage Period</Text> that are
            eligible for reimbursement under their{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text>
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Coverage Period</Text> means the
            time period specified on the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text>{' '}
            beginning on the{' '}
            <Text style={styles.fontNormalBold}>Effective Date</Text> and ending
            on the renewal date of the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> or the date
            an <Text style={styles.fontNormalBold}>Insured</Text> is no longer
            an <Text style={styles.fontNormalBold}>Eligible Person</Text>. All
            dates are as of 12:01 AM in the time zone of the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Declarations Page</Text> means
            the page sent to each{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> with specific
            information about the{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text> regarding{' '}
            <Text style={styles.fontNormalBold}>
              Coverage Period, Coverages
            </Text>
            , limits of liability, and premiums.
          </Text>
        </View>
        <View style={[styles.positionPage1Header, { bottom: '0px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>6</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Deductible</Text> means the
            amount of{' '}
            <Text style={styles.fontNormalBold}>Covered Expenses</Text> that
            must be paid by the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text>, during the{' '}
            <Text style={styles.fontNormalBold}>Coverage Period</Text>, for each{' '}
            <Text style={styles.fontNormalBold}>Pet</Text> before We will pay a{' '}
            <Text style={styles.fontNormalBold}>Claim</Text> for{' '}
            <Text style={styles.fontNormalBold}>Covered Expenses</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Effective Date</Text> of{' '}
            <Text style={styles.fontNormalBold}>Coverage</Text> means the date
            when the <Text style={styles.fontNormalBold}>Pet</Text> was first
            covered under this <Text style={styles.fontNormalBold}>Policy</Text>
            , unless otherwise stated on the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Eligible Class</Text> means a
            classification defined by the{' '}
            <Text style={styles.fontNormalBold}>Group</Text> and shown in the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text>. Each{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> must be an{' '}
            <Text style={styles.fontNormalBold}>Eligible Person</Text> of an{' '}
            <Text style={styles.fontNormalBold}>Eligible Class</Text> in order
            to be eligible for this insurance.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Eligible Person</Text> means an
            individual who is{' '}
            <Text style={styles.fontNormalBold}>Employed</Text> by or{' '}
            <Text style={styles.fontNormalBold}>Contracted</Text> with, the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text>,
            receiving compensation, and in an{' '}
            <Text style={styles.fontNormalBold}>Eligible Class</Text> (as shown
            in the <Text style={styles.fontNormalBold}> Declarations Page</Text>
            ) that has a <Text style={styles.fontNormalBold}>Pet</Text> to be
            covered.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Emergency Treatment</Text> means
            immediate medical attention is required or the life or well-being of
            the <Text style={styles.fontNormalBold}>Pet</Text> will be
            compromised.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Employed</Text> means a person
            who is reported on the{' '}
            <Text style={styles.fontNormalBold}>Group’s</Text> records for
            Social Security and tax withholding purposes.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Group Effective Date</Text>{' '}
            means the date (shown in the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text>) the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> issued to
            the <Text style={styles.fontNormalBold}>Group</Text> became
            effective.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Group Policy</Text> or{' '}
            <Text style={styles.fontNormalBold}>Policy</Text> means the contract
            between <Text style={styles.fontNormalBold}>Us</Text> and the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> to
            provide the <Text style={styles.fontNormalBold}>Coverage</Text>{' '}
            described in this{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text>, along with
            the <Text style={styles.fontNormalBold}>Declarations Pages</Text>{' '}
            and any <Text style={styles.fontNormalBold}>Riders</Text>, issued to
            the <Text style={styles.fontNormalBold}>You</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Group</Text> and{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> mean
            the <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            named in the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Illness(es)</Text> means
            sickness, disease, or any change in a{' '}
            <Text style={styles.fontNormalBold}>Pet’s</Text> normal, healthy
            state, which is not caused by{' '}
            <Text style={styles.fontNormalBold}>Injury</Text> to the{' '}
            <Text style={styles.fontNormalBold}>Pet</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Immediate Relative</Text> means
            any person related to an{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> by blood,
            marriage, legal adoption, or a resident in an{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> household.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Inherited</Text> means an{' '}
            <Text style={styles.fontNormalBold}>Illness</Text>, disease, or
            condition whose presence is determined by genetic factor.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>
              Initial Enrollment Waiting Period
            </Text>{' '}
            means the time period set by the{' '}
            <Text style={styles.fontNormalBold}>Group</Text> (as shown in the
            <Text style={styles.fontNormalBold}>Declarations Page</Text>) that
            an <Text style={styles.fontNormalBold}>Insured</Text> must be an{' '}
            <Text style={styles.fontNormalBold}>Eligible Person</Text> before
            they may enroll their <Text style={styles.fontNormalBold}>Pet</Text>{' '}
            in Coverage.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Injury(ies)</Text> means
            physical harm or damage to an{' '}
            <Text style={styles.fontNormalBold}>Insureds Pet</Text>, caused by
            an <Text style={styles.fontNormalBold}>Accident</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Insured</Text> means the{' '}
            <Text style={styles.fontNormalBold}>Eligible Person</Text> whose{' '}
            <Text style={styles.fontNormalBold}>Pet </Text> is insured under
            this <Text style={styles.fontNormalBold}>Policy</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Medically Necessary</Text> means
            medical services,{' '}
            <Text style={styles.fontNormalBold}>Supplies</Text> or care provided
            to treat <Text style={styles.fontNormalBold}>Pet(s)</Text> which
            are:
          </Text>
        </View>
        <View style={[styles.positionParagraph, { marginLeft: '17px' }]}>
          <OrderedList
            points={[
              <Text>
                Consistent with{' '}
                <Text style={styles.fontNormalBold}>Symptoms</Text> or
                diagnosis.
              </Text>,
              <Text>Accepted as good veterinary practice standards</Text>,
              <Text>
                Not for the ease or the request of the Pet(s) owner,
                Veterinarian or other providers.
              </Text>,
              <Text>
                Consistent with proper supply or level of services which can be
                safely provided to the Pet(s)
              </Text>,
            ]}
            type="number"
          />
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Medication(s)</Text> means any
            veterinary recommended{' '}
            <Text style={styles.fontNormalBold}>Medication(s)</Text> prescribed
            by a <Text style={styles.fontNormalBold}>Veterinarian</Text> and
            approved by the Food and Drug Administration (FDA) of the United
            States. FDA-approved drugs must be dispensed directly by the{' '}
            <Text style={styles.fontNormalBold}>Insureds Veterinarian</Text> or
            dispensed by a pharmacist with a prescription written by the{' '}
            <Text style={styles.fontNormalBold}>
              Insureds Veterinarian. Medication(s)
            </Text>{' '}
            includes medical <Text style={styles.fontNormalBold}>Supplies</Text>{' '}
            required to administer those{' '}
            <Text style={styles.fontNormalBold}>Medication(s)</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Noncontributory</Text> means the
            <Text style={styles.fontNormalBold}>Group</Text> pays the entire
            premium for insurance.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>
              Orthopedic Benefit Waiting Period
            </Text>{' '}
            means the time period (as shown in the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text>) each{' '}
            <Text style={styles.fontNormalBold}>Pet</Text> must wait before{' '}
            <Text style={styles.fontNormalBold}>Coverage</Text> is payable for
            an <Text style={styles.fontNormalBold}>Injury</Text> to the cruciate
            ligament. The{' '}
            <Text style={styles.fontNormalBold}>
              Orthopedic Benefit Waiting Period
            </Text>{' '}
            starts from the{' '}
            <Text style={styles.fontNormalBold}>
              Effective Date of Coverage
            </Text>
            . An orthopedic <Text style={styles.fontNormalBold}>Accident</Text>{' '}
            that occurs during the{' '}
            <Text style={styles.fontNormalBold}>
              Orthopedic Benefit Waiting Period
            </Text>{' '}
            will be excluded from{' '}
            <Text style={styles.fontNormalBold}>Coverage</Text> as a{' '}
            <Text style={styles.fontNormalBold}>Pre-existing Condition</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Pet</Text> means a cat or dog
            named and described on the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text> and
            both owned by the <Text style={styles.fontNormalBold}>Insured</Text>{' '}
            and residing with the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> for companionship
            or as a service dog, not owned for commercial reasons.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Pre-Existing Condition</Text>{' '}
            means any <Text style={styles.fontNormalBold}>Illness</Text> or{' '}
            <Text style={styles.fontNormalBold}>Injury</Text> which occurred,
            reoccurred, existed, or showed{' '}
            <Text style={styles.fontNormalBold}>Symptoms</Text> whether or not
            diagnosed and/or treated by a{' '}
            <Text style={styles.fontNormalBold}>Veterinarian</Text>, from either
            the <Text style={styles.fontNormalBold}>Pets’</Text> date of birth,
            6 months or 12 months prior to the{' '}
            <Text style={styles.fontNormalBold}>Effective Date</Text> or during
            the{' '}
            <Text style={styles.fontNormalBold}>Benefit Waiting Period</Text> as
            shown on the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text>.
          </Text>
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '10px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>7</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View style={styles.positionParagraph}>
          <Text>
            If an <Text style={styles.fontNormalBold}>Insured</Text> cannot
            provide medical records showing their{' '}
            <Text style={styles.fontNormalBold}>Pet’s</Text> annual health exam
            by a <Text style={styles.fontNormalBold}>Veterinarian</Text>{' '}
            occurring from the <Text style={styles.fontNormalBold}>Pets’</Text>{' '}
            date of birth or within the six (6) or twelve (12) months prior to
            the <Text style={styles.fontNormalBold}>Effective Date</Text> of the{' '}
            <Text style={styles.fontNormalBold}>Insureds Certificate</Text> as
            shown on the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text>, the
            first documented veterinary examination after the{' '}
            <Text style={styles.fontNormalBold}>Effective Date</Text> of the{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text> will be used
            as the basis for determining any{' '}
            <Text style={styles.fontNormalBold}>Injuries</Text> which may be{' '}
            <Text style={styles.fontNormalBold}>Pre-existing</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            After a period of twelve (12) months, a{' '}
            <Text style={styles.fontNormalBold}>Pre-Existing Condition</Text>{' '}
            will no longer be subject to the{' '}
            <Text style={styles.fontNormalBold}>Pre-existing Condition</Text>
            exclusion.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            If applicable as shown on the{' '}
            <Text style={styles.fontNormalBold}>
              Insureds Declarations Page
            </Text>
            ; If their <Text style={styles.fontNormalBold}>Pet</Text> was
            covered under a prior pet insurance policy that was in effect
            immediately before the{' '}
            <Text style={styles.fontNormalBold}>Effective Date</Text>, with no
            gap in coverage of this Group Policy, then credit toward satisfying
            the <Text style={styles.fontNormalBold}>Waiting Periods</Text> and{' '}
            <Text style={styles.fontNormalBold}>Pre-Existing Condition</Text>{' '}
            provisions will be given for the number of full months the pet was
            covered under the prior policy. The prior policy must have provided
            comparable benefits to the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> and may not
            be an accident only, trial plan or emergency pet insurance policy.
            Submission of the prior policy declarations page and/or the last
            billing statement may be required to receive this credit.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>
              Reasonable and Customary Charges
            </Text>{' '}
            means typical fees or the cost that{' '}
            <Text style={styles.fontNormalBold}>Veterinarians</Text> charge in
            an <Text style={styles.fontNormalBold}>Insureds</Text> geographic
            area based on available veterinary fee information and proprietary
            data
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Rider</Text> means optional
            additional <Text style={styles.fontNormalBold}>Coverage</Text> added
            to the <Text style={styles.fontNormalBold}>Certificate</Text> by the
            <Text style={styles.fontNormalBold}>Group Policyholder</Text>. If a{' '}
            <Text style={styles.fontNormalBold}>Rider</Text> is specified on the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page</Text> the{' '}
            <Text style={styles.fontNormalBold}>Coverage</Text> becomes a part
            of the <Text style={styles.fontNormalBold}> Policy</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Special Enrollment Period</Text>{' '}
            (if applicable) means an enrollment period for{' '}
            <Text style={styles.fontNormalBold}>Eligible Persons</Text> to
            apply, subject to the{' '}
            <Text style={styles.fontNormalBold}>Special Enrollment Period</Text>{' '}
            provision in section “Eligibility and Effective Dates”.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Supplies</Text> means any item
            that is{' '}
            <Text style={styles.fontNormalBold}>Medically Necessary</Text>, and
            provided by the{' '}
            <Text style={styles.fontNormalBold}>Veterinarian</Text> that is safe
            and effective for its intended use, and that omission would
            adversely affect the <Text style={styles.fontNormalBold}>Pet</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Symptoms</Text> means the first
            departure from normal function or feeling which is noticed by an{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> or their{' '}
            <Text style={styles.fontNormalBold}>Veterinarian</Text>, reflecting
            the presence of an{' '}
            <Text style={styles.fontNormalBold}>Illness</Text> or{' '}
            <Text style={styles.fontNormalBold}>Injury</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Treatment</Text> means any
            laboratory test, x-rays, medication, surgery, hospitalization,
            nursing and care provided or prescribed by a{' '}
            <Text style={styles.fontNormalBold}>Veterinarian</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Veterinarian</Text> means a
            currently licensed Doctor of Veterinary Medicine.{' '}
            <Text style={styles.fontNormalBold}>Veterinarian</Text> cannot be
            the <Text style={styles.fontNormalBold}>Insured</Text> or an{' '}
            <Text style={styles.fontNormalBold}>
              Insureds Immediate Relative
            </Text>
            .
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Veterinary Provider</Text> means
            a <Text style={styles.fontNormalBold}>Veterinarian</Text>,
            veterinary technician, or veterinary nurse currently licensed in the
            state, country or territory in which{' '}
            <Text style={styles.fontNormalBold}>Treatment</Text> is performed.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>We, Us</Text> and{' '}
            <Text style={styles.fontNormalBold}>Our</Text> means Independence
            American Insurance Company.
          </Text>
        </View>
        <View style={[styles.positionContentTitle, { marginTop: '25px' }]}>
          <Text style={styles.fontMediumBold}>
            Eligibility and Effective Dates
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormalBold}>Eligibility for Insurance</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            To be eligible for{' '}
            <Text style={styles.fontNormalBold}>coverage</Text>, each individual
            must satisfy the following requirements:
          </Text>
        </View>
        <View
          style={[styles.positionParagraph, { margin: '0 17px 10px 17px' }]}
        >
          <OrderedList
            points={[
              <Text>
                Be an <Text style={styles.fontNormalBold}>Eligible Person</Text>
                .
              </Text>,
              <Text>
                Be a citizen or legal resident of the United States of America
                or one of its territories.
              </Text>,
              <Text>
                Satisfy the{' '}
                <Text style={styles.fontNormalBold}>
                  Initial Enrollment Waiting Period
                </Text>{' '}
                for insurance, if any, and enroll within 31 days of satisfying
                the{' '}
                <Text style={styles.fontNormalBold}>
                  Initial Enrollment Waiting Period
                </Text>
                .
              </Text>,
              <Text>
                The individual cannot be an{' '}
                <Text style={styles.fontNormalBold}>Eligible Person</Text> of
                more than one{' '}
                <Text style={styles.fontNormalBold}>Eligible Class</Text>.
              </Text>,
              <Text>Premiums are paid when due.</Text>,
            ]}
            type="number"
          />
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormalBold}>Special Enrollment Period</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            If an Eligible Person does not enroll when first eligible, he or she
            may enroll for insurance, other than at the Annual Enrollment
            Period, when the Eligible Person acquires a Pet under one of the
            following circumstances:
          </Text>
        </View>
        <View
          style={[
            styles.positionParagraph,
            { marginLeft: '17px', marginRight: '17px' },
          ]}
        >
          <OrderedList
            points={[
              <Text>
                Adoption or birth of a pet that is at least 8 weeks of age.
              </Text>,
              <Text>
                Became married, and the spouse owns a pet that is at least 8
                weeks of age or older.
              </Text>,
            ]}
            type="number"
          />
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '30px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>8</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View style={styles.positionParagraph}>
          <Text>
            The{' '}
            <Text style={styles.fontNormalBold}>Special Enrollment Period</Text>{' '}
            expires within 31 days after the occurrence of one of the above
            events.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormalBold}>Increases in Insurance</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            An increase in insurance can only occur at the{' '}
            <Text style={styles.fontNormalBold}>Annual Enrollment Period</Text>.
            An increase in insurance is effective on the first day of the month
            immediately following the month the individual is eligible for such
            insurance, except if the individual is eligible on the first day of
            a month, insurance is effective on that day.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormalBold}>Leaves of Absence</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.underLine}>Approved FMLA Leave of Absence</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Contributory</Text> or{' '}
            <Text style={styles.fontNormalBold}>Noncontributory</Text> Premiums:
            If an <Text style={styles.fontNormalBold}>Insured</Text> is on leave
            with the Federal Family and Medical Leave Act of 1993 (FMLA), as
            amended, insurance will continue until the later of the required
            FMLA leave period or the leave period required by applicable state
            law, if:
          </Text>
        </View>
        <View
          style={[
            styles.positionParagraph,
            { marginLeft: '17px', marginRight: '17px' },
          ]}
        >
          <OrderedList
            points={[
              <Text>
                FMLA Leaves, and the right to continue insurance during FMLA
                Leaves, are available to all{' '}
                <Text style={styles.fontNormalBold}>Insureds</Text> in the same{' '}
                <Text style={styles.fontNormalBold}>Eligible Class</Text> under
                the <Text style={styles.fontNormalBold}>Group Policy</Text>;
              </Text>,
              <Text>
                the <Text style={styles.fontNormalBold}>Group</Text> remits the
                required insurance premium; and
              </Text>,
              <Text>
                the FMLA Leave is approved in advance by the{' '}
                <Text style={styles.fontNormalBold}>Group</Text> and the
                approval includes documentation with the beginning and ending
                dates of the leave. Documentation about the{' '}
                <Text style={styles.fontNormalBold}>Insureds</Text> leave must
                be available to <Text style={styles.fontNormalBold}>Us</Text> at{' '}
                <Text style={styles.fontNormalBold}>Our</Text> request.
              </Text>,
            ]}
            type="number"
          />
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.underLine}>
            Paid Leave of Absence, including sabbaticals.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Noncontributory</Text> insurance
            will continue if:
          </Text>
        </View>
        <View
          style={[
            styles.positionParagraph,
            { marginLeft: '17px', marginRight: '17px' },
          ]}
        >
          <OrderedList
            points={[
              <Text>
                We receive advance written notice of a paid leave approved by
                the <Text style={styles.fontNormalBold}>Group</Text>, with the
                beginning and ending dates of the paid leave; and
              </Text>,
              <Text>
                Paid leaves, and the right to continue insurance during paid
                leaves, are available to all{' '}
                <Text style={styles.fontNormalBold}>Insureds</Text> in the same{' '}
                <Text style={styles.fontNormalBold}>Eligible Class</Text>; and
              </Text>,
              <Text>
                The <Text style={styles.fontNormalBold}>Group</Text> remits the
                required premium for insurance.
              </Text>,
            ]}
            type="number"
          />
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Contributory</Text> insurance
            will continue if:
          </Text>
        </View>
        <View
          style={[
            styles.positionParagraph,
            { marginLeft: '17px', marginRight: '17px' },
          ]}
        >
          <OrderedList
            points={[
              <Text>
                We receive advance written notice of a paid leave approved by
                the <Text style={styles.fontNormalBold}>Group</Text>, with the
                beginning and ending dates of the paid leave; and
              </Text>,
              <Text>
                Paid leaves, and the right to continue insurance during paid
                leaves, are available to all{' '}
                <Text style={styles.fontNormalBold}>Insureds</Text> in the same{' '}
                <Text style={styles.fontNormalBold}>Eligible Class</Text>; and
              </Text>,
              <Text>
                The <Text style={styles.fontNormalBold}>Insured</Text> continues
                to pay the required premium to the{' '}
                <Text style={styles.fontNormalBold}>Group</Text> without
                interruption, and the{' '}
                <Text style={styles.fontNormalBold}>Group</Text> continues to
                remit premium to <Text style={styles.fontNormalBold}>Us</Text>.
              </Text>,
            ]}
            type="number"
          />
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Noncontributory</Text> and{' '}
            <Text style={styles.fontNormalBold}>Contributory</Text> insurance:
          </Text>
          <Text>
            Unless an <Text style={styles.fontNormalBold}>Insured</Text> returns
            to active, eligible status on or before the date the paid leave is
            scheduled to end, insurance extended during a paid leave will
            terminate on the earlier of the date the paid leave is scheduled to
            end, 3 months from the date the paid leave began or the date premium
            is not paid as required. Contributory insurance also terminates on
            the date the required premium is not paid.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.underLine}>
            Unpaid Leave of Absence, including sabbaticals
          </Text>
        </View>
        <View>
          <Text>
            <Text style={styles.fontNormalBold}>Noncontributory</Text> insurance
            will continue if:
          </Text>
        </View>
        <View
          style={[
            styles.positionParagraph,
            { marginLeft: '17px', marginRight: '17px' },
          ]}
        >
          <OrderedList
            points={[
              <Text>
                <Text style={styles.fontNormalBold}>We</Text> receive advance
                written notice of an unpaid leave approved by the{' '}
                <Text style={styles.fontNormalBold}>Group</Text> with the
                beginning and ending dates of the unpaid leave; and
              </Text>,
              <Text>
                Unpaid leaves, and the right to continue insurance during unpaid
                leaves, are available to all{' '}
                <Text style={styles.fontNormalBold}>Insureds</Text> in the same{' '}
                <Text style={styles.fontNormalBold}>Eligible Class</Text>; and
              </Text>,
              <Text>
                The <Text style={styles.fontNormalBold}>Group</Text> remits the
                required premium for insurance.
              </Text>,
            ]}
            type="number"
          />
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Contributory</Text> insurance
            will continue if:
          </Text>
        </View>
        <View
          style={[
            styles.positionParagraph,
            { marginLeft: '17px', marginRight: '17px' },
          ]}
        >
          <OrderedList
            points={[
              <Text>
                <Text style={styles.fontNormalBold}>We</Text> receive advance
                written notice of an unpaid leave approved by the{' '}
                <Text style={styles.fontNormalBold}>Group</Text>, with the
                beginning and ending dates of the unpaid leave; and
              </Text>,
              <Text>
                Unpaid leaves, and the right to continue insurance during unpaid
                leaves, are available to all{' '}
                <Text style={styles.fontNormalBold}>Insureds</Text> in the same{' '}
                <Text style={styles.fontNormalBold}>Eligible Class</Text>; and
              </Text>,
              <Text>
                The <Text style={styles.fontNormalBold}>Insured</Text> continues
                to pay the required premium to the{' '}
                <Text style={styles.fontNormalBold}>Group</Text> without
                interruption and the{' '}
                <Text style={styles.fontNormalBold}>Group</Text> continues to
                remit premium to Us.
              </Text>,
            ]}
            type="number"
          />
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '20px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>9</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View>
          <Text>
            <Text style={styles.fontNormalBold}>
              Noncontributory and Contributory
            </Text>{' '}
            insurance:
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            Unless the <Text style={styles.fontNormalBold}>Insured</Text>{' '}
            returns to active, eligible status on or before the date the unpaid
            leave is scheduled to end, insurance extended during an unpaid leave
            will terminate on the earlier of the date the unpaid leave is
            scheduled to end, 3 months from the date the unpaid leave began] or
            the date premium is not paid as required.{' '}
            <Text style={styles.fontNormalBold}>Contributory</Text> insurance
            also terminates on the date the required premium is not paid.
          </Text>
        </View>
        <View>
          <Text>Labor Dispute.</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Contributory</Text> or{' '}
            <Text style={styles.fontNormalBold}>Noncontributory</Text>{' '}
            insurance: If an <Text style={styles.fontNormalBold}>Insured</Text>{' '}
            is on a paid or unpaid leave of absence due to a labor dispute,
            insurance will continue through the end of the 6-month period
            following the date the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> last worked prior
            to the labor dispute, if premium is paid for You during this period.
          </Text>
        </View>
        <View>
          <Text>Layoffs.</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Contributory</Text> or{' '}
            <Text style={styles.fontNormalBold}>Noncontributory</Text>{' '}
            insurance: If an <Text style={styles.fontNormalBold}>Insured</Text>{' '}
            is on a leave of absence due to a layoff, insurance continues
            through the end of the month the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> last worked prior
            to the layoff, if premium is paid for that period.
          </Text>
        </View>
        <View>
          <Text>Military Leave.</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            No benefits are payable, and no premiums are due, while the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> is on active
            military leave. If the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> returns to Active
            Work with the <Text style={styles.fontNormalBold}>Group</Text>,
            within the timeframes provided in the Uniformed Services Employment
            and Reemployment Rights Act, the{' '}
            <Text style={styles.fontNormalBold}>Insured’s</Text> insurance will
            be reinstated without a{' '}
            <Text style={styles.fontNormalBold}>Benefit Waiting Period</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            If an <Text style={styles.fontNormalBold}>Insured</Text> ceases to
            be an <Text style={styles.fontNormalBold}>Eligible Person</Text> and
            insurance ends except due to layoff, and then the individual again
            becomes an{' '}
            <Text style={styles.fontNormalBold}>Eligible Person</Text> in all
            respects within 3 months, coverage will be reinstated as if there
            was no gap in coverage.
          </Text>
        </View>
        <View style={[styles.positionContentTitle, { marginTop: '25px' }]}>
          <Text style={styles.fontMediumBold}>Conditions of Coverage</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            In order for a <Text style={styles.fontNormalBold}>Claim</Text> to
            be considered and paid by{' '}
            <Text style={styles.fontNormalBold}>Us</Text>, the following
            conditions must be met:
          </Text>
        </View>
        <View style={[{ marginRight: '17px' }]}>
          <OrderedList
            points={[
              <Text>
                Upon submission of the{' '}
                <Text style={styles.fontNormalBold}>Insureds</Text> first{' '}
                <Text style={styles.fontNormalBold}>Claim</Text>, if requested,
                the <Text style={styles.fontNormalBold}>Insured</Text> must
                provide documents which validates ownership of the{' '}
                <Text style={styles.fontNormalBold}>Pet</Text>, and/or the{' '}
                <Text style={styles.fontNormalBold}>Insured</Text> must provide
                the most recent 12 months of medical records, unless the{' '}
                <Text style={styles.fontNormalBold}>Claim</Text> is for routine
                preventative wellness or dental care only. The{' '}
                <Text style={styles.fontNormalBold}>Insured</Text> also agrees,
                that by obtaining this{' '}
                <Text style={styles.fontNormalBold}>Coverage</Text>, the{' '}
                <Text style={styles.fontNormalBold}>Insured</Text> gives{' '}
                <Text style={styles.fontNormalBold}>Us</Text> permission to
                gather all medical information for their{' '}
                <Text style={styles.fontNormalBold}>Pet</Text> from all{' '}
                <Text style={styles.fontNormalBold}>Veterinary Providers</Text>,
                as <Text style={styles.fontNormalBold}>We</Text> deem necessary.
              </Text>,
              <Text>
                All <Text style={styles.fontNormalBold}>Treatment</Text> must be
                performed by a{' '}
                <Text style={styles.fontNormalBold}>Veterinary Provider</Text>{' '}
                that the <Text style={styles.fontNormalBold}>Insured</Text> may
                freely choose, as long as the{' '}
                <Text style={styles.fontNormalBold}>Veterinary Provider</Text>{' '}
                is not an{' '}
                <Text style={styles.fontNormalBold}>Immediate Relative</Text>.
              </Text>,
              <Text>
                The <Text style={styles.fontNormalBold}>Insured</Text> must
                arrange for a{' '}
                <Text style={styles.fontNormalBold}>Veterinarian</Text> to
                examine and treat their Pet as soon as possible after it shows{' '}
                <Text style={styles.fontNormalBold}>
                  Clinical Signs of Injury
                </Text>
                .
              </Text>,
              <Text>
                The <Text style={styles.fontNormalBold}>Insured</Text> is
                financially responsible to their{' '}
                <Text style={styles.fontNormalBold}>Veterinary Provider</Text>{' '}
                for payment of all{' '}
                <Text style={styles.fontNormalBold}>Treatment</Text>.
              </Text>,
              <Text>
                The <Text style={styles.fontNormalBold}>Insured</Text> must be
                the legal owner of their{' '}
                <Text style={styles.fontNormalBold}>Pet</Text> and their{' '}
                <Text style={styles.fontNormalBold}>Pet</Text> must reside with
                and be under the{' '}
                <Text style={styles.fontNormalBold}>Insureds</Text> regular care
                and supervision at the physical address listed on the{' '}
                <Text style={styles.fontNormalBold}>Declarations Page</Text>.
              </Text>,
              <Text>
                If the <Text style={styles.fontNormalBold}>Insured</Text> does
                not know the exact date of birth of their{' '}
                <Text style={styles.fontNormalBold}>Pet, We</Text> will use the
                average of the estimates of their{' '}
                <Text style={styles.fontNormalBold}>Pet’s</Text> age as
                referenced in the{' '}
                <Text style={styles.fontNormalBold}>Pet’s</Text> medical records
                from the veterinary clinics and shelters.
              </Text>,
              <View style={{ flexDirection: 'column', marginRight: '20px' }}>
                <Text style={styles.positionParagraph}>
                  The <Text style={styles.fontNormalBold}>Insured</Text> must
                  ensure that their{' '}
                  <Text style={styles.fontNormalBold}>Pet</Text> receives:
                </Text>
                <OrderedList
                  points={[
                    <Text>An annual health check;</Text>,
                    <Text>
                      An annual dental exam and, if recommended, prophylaxis
                      (defined as ultrasonic scaling and polishing of the teeth)
                    </Text>,
                    <Text>
                      <Text style={styles.fontNormalBold}>Treatment</Text>{' '}
                      normally suggested by a{' '}
                      <Text style={styles.fontNormalBold}>Veterinarian</Text> to
                      prevent{' '}
                      <Text style={styles.fontNormalBold}>
                        Illness or Injury
                      </Text>
                      ; and
                    </Text>,
                    <Text>
                      Appropriate prophylactic{' '}
                      <Text style={styles.fontNormalBold}>Medication</Text> as
                      prescribed and dispensed by the{' '}
                      <Text style={styles.fontNormalBold}>
                        Insureds Veterinarian
                      </Text>{' '}
                      to protect against{' '}
                      <Text style={styles.fontNormalBold}>Illness</Text>,
                      including but not limited to lice, parasites and fleas.{' '}
                      <Text style={styles.fontNormalBold}>We</Text> will not pay
                      Claims for{' '}
                      <Text style={styles.fontNormalBold}>Illnesses</Text> or{' '}
                      <Text style={styles.fontNormalBold}>Injuries</Text> as a
                      result of the{' '}
                      <Text style={styles.fontNormalBold}>Insureds</Text>{' '}
                      failure to comply with this requirement; and
                    </Text>,
                  ]}
                  pointStyleObject={styles.positionParagraph}
                  type="alpha"
                />
              </View>,
            ]}
            pointStyleObject={styles.positionParagraph}
            type="number"
          />
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '10px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>10</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View style={[{ marginRight: '17px' }]}>
          <OrderedList
            points={[
              <View style={{ flexDirection: 'column', marginRight: '20px' }}>
                <OrderedList
                  points={[
                    <Text>
                      Appropriate prophylactic{' '}
                      <Text style={styles.fontNormalBold}>Medication</Text>{' '}
                      and/or vaccination as prescribed and dispensed by the{' '}
                      <Text style={styles.fontNormalBold}>
                        Insureds Veterinarian
                      </Text>{' '}
                      to protect against tick-borne{' '}
                      <Text style={styles.fontNormalBold}>Illnesses</Text>.{' '}
                      <Text style={styles.fontNormalBold}>We</Text> will not pay{' '}
                      <Text style={styles.fontNormalBold}>Claims</Text> for{' '}
                      <Text style={styles.fontNormalBold}>Illness</Text> or{' '}
                      <Text style={styles.fontNormalBold}>Injuries</Text> as a
                      result of the{' '}
                      <Text style={styles.fontNormalBold}>Insured</Text> faliure
                      to comply with this requirement.
                    </Text>,
                  ]}
                  pointStyleObject={styles.positionParagraph}
                  startNumber={5}
                  type="alpha"
                />
              </View>,
              <Text>
                The <Text style={styles.fontNormalBold}>Insured</Text> must act
                prudently in the care and protection of their{' '}
                <Text style={styles.fontNormalBold}>Pet</Text>. The{' '}
                <Text style={styles.fontNormalBold}>Insured</Text> must protect
                their <Text style={styles.fontNormalBold}>Pet</Text> from
                aggravation or recurrence of any{' '}
                <Text style={styles.fontNormalBold}>Injury</Text> after its
                initial occurrence and provide proper maintenance/preventive
                care.
              </Text>,
              <Text>
                As recommended by the{' '}
                <Text style={styles.fontNormalBold}>Insureds Veterinarian</Text>{' '}
                and at the <Text style={styles.fontNormalBold}>Insureds</Text>{' '}
                expense, the <Text style={styles.fontNormalBold}>Insured</Text>{' '}
                must keep their <Text style={styles.fontNormalBold}>Pet</Text>{' '}
                vaccinated. <Text style={styles.fontNormalBold}>We</Text> will
                not pay <Text style={styles.fontNormalBold}>Claims</Text> that
                result from or are related to any Illness that a
                Veterinarian-recommended vaccine would have prevented.
              </Text>,
            ]}
            pointStyleObject={styles.positionParagraph}
            startNumber={7}
            type="number"
          />
        </View>
        <View style={styles.positionContentTitle}>
          <Text style={styles.fontMediumBold}>Coverage</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            As shown on the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page(s)</Text>, the
            following <Text style={styles.fontNormalBold}>Coverages</Text> apply
            separately to each <Text style={styles.fontNormalBold}>pet</Text>.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Coverage</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>We</Text> will reimburse the{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text> for{' '}
            <Text style={styles.fontNormalBold}>
              Medically Necessary Supplies and Treatment(s)
            </Text>{' '}
            performed, subject to any applicable{' '}
            <Text style={styles.fontNormalBold}>Deductible</Text> and{' '}
            <Text style={styles.fontNormalBold}>Coinsurance</Text> performed for
            conditions that started after the{' '}
            <Text style={styles.fontNormalBold}>Benefit Waiting Period</Text>{' '}
            and during the{' '}
            <Text style={styles.fontNormalBold}>Coverage Period</Text>, which
            result from:
          </Text>
        </View>
        <View
          style={[
            styles.positionParagraph,
            { marginLeft: '10px', marginRight: '20px' },
          ]}
        >
          <OrderedList
            points={[
              <Text>
                <Text style={styles.fontNormalBold}>Accidents</Text>, including
                but not limited to, an automobile{' '}
                <Text style={styles.fontNormalBold}>Accident</Text>, ingestion
                of a foreign body, poisoning, animal bites, as well as{' '}
                <Text style={styles.fontNormalBold}>Accidents</Text> resulting
                in dental trauma, burns, and fractures. Orthopedic{' '}
                <Text style={styles.fontNormalBold}>Accidents</Text> are subject
                to the{' '}
                <Text style={styles.fontNormalBold}>
                  Orthopedic Benefit Waiting Period
                </Text>
                ;
              </Text>,
              <Text style={styles.fontNormalBold}>Illnesses;</Text>,
              <Text>
                <Text style={styles.fontNormalBold}>We</Text> will reimburse the{' '}
                <Text style={styles.fontNormalBold}>Insureds</Text> for the cost
                of <Text style={styles.fontNormalBold}>Treatment</Text> the{' '}
                <Text style={styles.fontNormalBold}>Insured’s Pet</Text>{' '}
                receives in the current period of insurance for an Injury that
                first showed{' '}
                <Text style={styles.fontNormalBold}>Clinical Signs</Text> after
                the end of the{' '}
                <Text style={styles.fontNormalBold}>
                  Benefit Waiting Period
                </Text>{' '}
                and <Text style={styles.fontNormalBold}>Treatment</Text>{' '}
                required due to{' '}
                <Text style={styles.fontNormalBold}>Injury</Text>, subject to
                any limitations and exclusions.
              </Text>,
              <Text>
                <Text style={styles.fontNormalBold}>We</Text> will reimburse the
                Insureds for the prescription{' '}
                <Text style={styles.fontNormalBold}>Medications</Text> dispensed
                directly by the{' '}
                <Text style={styles.fontNormalBold}>Insureds Veterinarian</Text>{' '}
                or compounded by a pharmacist under the guidance of the{' '}
                <Text style={styles.fontNormalBold}>Insureds Veterinarian</Text>{' '}
                as <Text style={styles.fontNormalBold}>Treatment</Text> for
                covered <Text style={styles.fontNormalBold}>Accidents</Text>{' '}
                that occur during the{' '}
                <Text style={styles.fontNormalBold}>Coverage Period</Text>. This
                does not include{' '}
                <Text style={styles.fontNormalBold}>Coverage</Text> for over the
                counter medications, whether or not recommended or prescribed by
                the <Text style={styles.fontNormalBold}>Veterinarian</Text>.
              </Text>,
            ]}
            type="number"
          />
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Cost Shares</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>We</Text> will apply the{' '}
            <Text style={styles.fontNormalBold}>Coinsurance</Text> to eligible{' '}
            <Text style={styles.fontNormalBold}>Covered Expenses</Text> after
            satisfaction of the{' '}
            <Text style={styles.fontNormalBold}>Insureds Deductible</Text>. Once
            the <Text style={styles.fontNormalBold}>Deductible</Text> is
            satisfied, We will pay the{' '}
            <Text style={styles.fontNormalBold}>Insureds Claim</Text> subject to
            the <Text style={styles.fontNormalBold}>Insureds Coinsurance</Text>,
            up to the <Text style={styles.fontNormalBold}>Annual Limit</Text> as
            shown on the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page(s)</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            Once the <Text style={styles.fontNormalBold}>Insureds</Text>{' '}
            Deductible is reached, <Text style={styles.fontNormalBold}>We</Text>{' '}
            will pay the{' '}
            <Text style={styles.fontNormalBold}>Insureds Claim</Text> subject to
            the <Text style={styles.fontNormalBold}>Insureds Coinsurance</Text>.
            When the <Text style={styles.fontNormalBold}>Treatment</Text> dates
            of an <Text style={styles.fontNormalBold}>Illness or Injury</Text>{' '}
            fall into two or more{' '}
            <Text style={styles.fontNormalBold}>Coverage Periods</Text>, the{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text> will be required
            to pay a <Text style={styles.fontNormalBold}>Deductible</Text> for
            each <Text style={styles.fontNormalBold}>Coverage Period</Text>.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Limits of Insurance</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            Regardless of the number of{' '}
            <Text style={styles.fontNormalBold}>Claims</Text> made or covered
            Injuries that occur during the{' '}
            <Text style={styles.fontNormalBold}>Coverage Period</Text>, Our
            total liability for each{' '}
            <Text style={styles.fontNormalBold}>Coverage Period</Text> for all
            covered benefits shall not exceed the amounts shown on the{' '}
            <Text style={styles.fontNormalBold}>Declarations Page(s)</Text>{' '}
            under <Text style={styles.fontNormalBold}>Annual Limit</Text>.
          </Text>
        </View>
        <View style={[styles.positionContentTitle, { marginTop: '25px' }]}>
          <Text style={styles.fontMediumBold}>Exclusions</Text>
        </View>
        <View style={[styles.positionParagraph, { marginRight: '20px' }]}>
          <OrderedList
            points={[
              <View style={{ flexDirection: 'column', marginRight: '20px' }}>
                <Text>
                  <Text style={styles.fontNormalBold}>Pre-existing</Text>{' '}
                  Conditions including, but not limited to:
                </Text>
                <OrderedList
                  points={[
                    <Text>
                      <Text style={styles.fontNormalBold}>
                        Bilateral Condition
                      </Text>
                      , presenting on one side of the body. For example, a
                      cruciate tear in the left leg that showed{' '}
                      <Text style={styles.fontNormalBold}>Symptoms</Text> prior
                      to the{' '}
                      <Text style={styles.fontNormalBold}>Coverage Period</Text>{' '}
                      or during a{' '}
                      <Text style={styles.fontNormalBold}>
                        Benefit Waiting Period
                      </Text>
                      , a subsequent cruciate tear in the right leg will be
                      considered Pre-existing.
                    </Text>,
                    <Text>
                      IVDD (Intervertebral Disc Disease) if diagnosed, treated,
                      or showing{' '}
                      <Text style={styles.fontNormalBold}>Symptoms</Text> prior
                      to the{' '}
                      <Text style={styles.fontNormalBold}>Coverage Period</Text>{' '}
                      or during a{' '}
                      <Text style={styles.fontNormalBold}>
                        Benefit Waiting Period
                      </Text>{' '}
                      and any further episodes of IVDD or any future occurrence
                      of this condition.
                    </Text>,
                  ]}
                  type="alpha"
                />
              </View>,
              <Text>
                Any services not performed by, or under the direct supervision
                or within the care of, a licensed{' '}
                <Text style={styles.fontNormalBold}>Veterinarian</Text>.
              </Text>,
              <Text>
                Professional fees and/or services performed by a{' '}
                <Text style={styles.fontNormalBold}>Veterinary Provider</Text>{' '}
                on their own pet or the pet of an{' '}
                <Text style={styles.fontNormalBold}>Immediate Relative</Text>
              </Text>,
              <Text>
                Procedures performed prior to the{' '}
                <Text style={styles.fontNormalBold}>Effective Date</Text> or
                during a{' '}
                <Text style={styles.fontNormalBold}>
                  Benefit Waiting Period
                </Text>
                .
              </Text>,
              <Text>
                Diagnostics or services for conditions that are excluded.
              </Text>,
              <Text>Over the counter medications.</Text>,
            ]}
            type="number"
          />
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '10px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>11</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View style={[styles.positionParagraph, { marginRight: '20px' }]}>
          <OrderedList
            points={[
              <View style={{ flexDirection: 'column', marginRight: '20px' }}>
                <Text>
                  <Text style={styles.fontNormalBold}>Treatment</Text>,{' '}
                  services, and{' '}
                  <Text style={styles.fontNormalBold}>Supplies</Text> deemed not{' '}
                  <Text style={styles.fontNormalBold}>Medically Necessary</Text>{' '}
                  or are considered experimental in nature, including but not
                  limited to:
                </Text>
                <OrderedList
                  points={[
                    <Text>
                      cosmetic, aesthetic, or elective surgeries, or any{' '}
                      <Text style={styles.fontNormalBold}>
                        Illness or Injury
                      </Text>{' '}
                      that results from such elective services or surgeries,
                      including the spaying or neutering of an{' '}
                      <Text style={styles.fontNormalBold}>Insureds</Text> pet;
                    </Text>,
                    <Text>
                      Membership fees for medical, wellness/preventative plans
                      or cash back programs
                    </Text>,
                    <Text>
                      Transplants, cloning, genetic testing, cell replacement;
                    </Text>,
                    <Text>
                      Grooming and grooming supplies, bathing, unless deemed
                      medically necessary by a{' '}
                      <Text style={styles.fontNormalBold}>Veterinarian</Text>{' '}
                      that it be performed by the{' '}
                      <Text style={styles.fontNormalBold}>Veterinarian</Text> or
                      a member of the veterinary staff, or anal gland
                      expression. This does not include surgical{' '}
                      <Text style={styles.fontNormalBold}>Treatment</Text> for
                      anal gland infection;
                    </Text>,
                    <Text>Crates, pet housing, leashes, and collars; or</Text>,
                    <Text>
                      Puppy/obedience training and any devices used in such
                      training.
                    </Text>,
                  ]}
                  type="alpha"
                />
              </View>,
              <View style={{ flexDirection: 'column', marginRight: '20px' }}>
                <Text>Cost of:</Text>
                <OrderedList
                  points={[
                    <Text>
                      Physical exams, office exams, house calls, consultations;
                      including costs or fees for telephone consultation, unless
                      shown on the{' '}
                      <Text style={styles.fontNormalBold}>
                        Declarations Page
                      </Text>
                      ;
                    </Text>,
                    <Text>
                      Diagnosing or Treatment of breeding, pregnancy, whelping
                      or queening, including complications thereof;
                    </Text>,
                    <Text>
                      Preventative, routine dental care, unless shown on the{' '}
                      <Text style={styles.fontNormalBold}>
                        Declarations Page
                      </Text>
                      ;
                    </Text>,
                    <Text>Toothbrushes, toothpaste, dental chews;</Text>,
                    <Text>
                      Preventative care and procedures, vaccinations and
                      microchipping unless shown on the{' '}
                      <Text style={styles.fontNormalBold}>
                        Declarations Page
                      </Text>
                      ;
                    </Text>,
                    <Text>
                      Internal and external parasites, and any related{' '}
                      <Text style={styles.fontNormalBold}>
                        Illness or Injury
                      </Text>{' '}
                      that results;
                    </Text>,
                    <Text>Natural supplements and vitamins;</Text>,
                    <Text>
                      Pet food, including prescription pet food, unless it is
                      the sole{' '}
                      <Text style={styles.fontNormalBold}>Treatment</Text> of a
                      condition;
                    </Text>,
                    <Text>
                      <Text style={styles.fontNormalBold}>Inherited</Text> or{' '}
                      <Text style={styles.fontNormalBold}>Congenital</Text>{' '}
                      conditions, including but not limited to diabetes, IVDD,
                      luxating patella, osteoarthritis, spondylosis, hip
                      dysplasia and birth defects, unless shown on the{' '}
                      <Text style={styles.fontNormalBold}>
                        Declarations Page
                      </Text>
                      ;
                    </Text>,
                    <Text>
                      Rehabilitation or Physical Therapy, unless shown on the{' '}
                      <Text style={styles.fontNormalBold}>
                        Declarations Page
                      </Text>
                      ;
                    </Text>,
                    <Text>
                      Holistic, Alternative and Behavioral therapy{' '}
                      <Text style={styles.fontNormalBold}>Treatment</Text>,
                      including but not limited to{' '}
                      <Text style={styles.fontNormalBold}>Treatment</Text> of
                      coprophagia, unless shown on the{' '}
                      <Text style={styles.fontNormalBold}>
                        Declarations Page
                      </Text>
                      ;
                    </Text>,
                    <Text>
                      Obesity unrelated to an underlying medical condition;
                    </Text>,
                    <Text>
                      Elective euthanasia not recommended by a{' '}
                      <Text style={styles.fontNormalBold}>Veterinarian</Text> or
                      for behavioral reasons;
                    </Text>,
                    <Text>
                      Necropsy and Cremation, burial costs or other methods of
                      disposing remains of an{' '}
                      <Text style={styles.fontNormalBold}>Insureds</Text> pet;
                    </Text>,
                    <View
                      style={{ flexDirection: 'column', marginRight: '30px' }}
                    >
                      <Text>
                        Transportation costs, including but not limited to:
                      </Text>
                      <OrderedList
                        points={[
                          <Text>
                            Non-emergency ground or air pet ambulance;
                          </Text>,
                          <Text>Emergency air pet ambulance;</Text>,
                          <Text>House calls; or</Text>,
                          <Text>
                            Travel to and from the{' '}
                            <Text style={styles.fontNormalBold}>
                              Veterinarian
                            </Text>
                            .
                          </Text>,
                        ]}
                        type="rs"
                      />
                    </View>,
                    <Text>
                      Boarding, unless pet is hospitalized for medical reasons;
                      or, in the case of an{' '}
                      <Text style={styles.fontNormalBold}>Insured’s</Text> own
                      hospitalization, this benefit is shown on the Declarations
                      Page;
                    </Text>,
                    <Text>
                      <Text style={styles.fontNormalBold}>Treatment</Text> due
                      to conditions related to racing, security, law
                      enforcement, working dogs and organized fighting; or
                    </Text>,
                    <Text>
                      <Text style={styles.fontNormalBold}>
                        Illness or Injury
                      </Text>{' '}
                      caused by: a) enemy attack by armed forces, with or
                      without a state of war, b) insurrection; c) rebellion; d)
                      revolution; e) invasion; f) civil war; g) illegal acts; h)
                      usurped power; i) nuclear radioactive contamination; j)
                      chemical, biological, biochemical or electromagnetic
                      weapon; k) acts of foreign enemies; l) strikes; m) riots;
                      n) civil commotion; or p) Avian or swine influenza or any
                      mutant variation.
                    </Text>,
                  ]}
                  type="alpha"
                />
              </View>,
              <Text>
                Intentional acts, neglect or deliberate endangerment by an{' '}
                <Text style={styles.fontNormalBold}>Insured</Text> or anyone
                living with the{' '}
                <Text style={styles.fontNormalBold}>Insured</Text> or having
                care, custody or control over their pet.
              </Text>,
              <Text>
                More than one{' '}
                <Text style={styles.fontNormalBold}>Illness or Injury</Text> per{' '}
                <Text style={styles.fontNormalBold}>Coverage Period</Text>, of a{' '}
                <Text style={styles.fontNormalBold}>Pet</Text> arising from a
                repetitive and specific activity or similar activity that has
                previously occurred and displayed the propensity for this
                activity to happen again and cause{' '}
                <Text style={styles.fontNormalBold}>Illness or Injury</Text> to
                an <Text style={styles.fontNormalBold}>Insureds Pet</Text>(s).
                (Examples include but are not limited to: foreign body
                ingestion, dog fights and toxin ingestion).
              </Text>,
              <View style={{ flexDirection: 'column', marginRight: '20px' }}>
                <Text>
                  Cost of <Text style={styles.fontNormalBold}>Treatments</Text>{' '}
                  for an <Text style={styles.fontNormalBold}>Insureds</Text>{' '}
                  failure to follow their{' '}
                  <Text style={styles.fontNormalBold}>Veterinarians</Text>{' '}
                  recommendation, including but not limited to:
                </Text>
                <OrderedList
                  points={[
                    <Text>
                      Additional cost of{' '}
                      <Text style={styles.fontNormalBold}>Treatments</Text>{' '}
                      after{' '}
                      <Text style={styles.fontNormalBold}>Veterinarian</Text>{' '}
                      recommended humane euthanasia, amputation of a limb or
                      removal of an eye; or
                    </Text>,
                    <Text>
                      Failure to follow recommended vaccination schedule.
                    </Text>,
                  ]}
                  type="alpha"
                />
              </View>,
              <View style={{ flexDirection: 'column', marginRight: '20px' }}>
                <Text>Costs or fees related, but not limited to:</Text>
                <OrderedList
                  points={[
                    <Text>Federal, state or local taxes;</Text>,
                    <Text>Government fees and surcharges;</Text>,
                    <Text>Photocopying Fees;</Text>,
                    <Text>Bank fees and credit card charges;</Text>,
                    <Text>Biohazardous waste fees and waste disposal;</Text>,
                    <Text>OSHA fees;</Text>,
                    <Text>Maintenance fees;</Text>,
                    <Text>
                      Attorney fees or defense costs related to any claim or
                      dispute; or
                    </Text>,
                    <Text>Missed appointment fees.</Text>,
                  ]}
                  type="alpha"
                />
              </View>,
            ]}
            startNumber={7}
            type="number"
          />
        </View>
        <View style={[styles.positionPage1Header]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>12</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View style={[styles.positionContentTitle]}>
          <Text style={styles.fontMediumBold}>General Provisions</Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Entire Contract, Changes</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            This <Text style={styles.fontNormalBold}>Group Policy</Text>,
            including the Group Application,{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text> and any
            endorsement,{' '}
            <Text style={styles.fontNormalBold}>
              Declarations Pages, Riders
            </Text>{' '}
            and other attached pages, if any, constitutes the entire contract of
            insurance. No change in this{' '}
            <Text style={styles.fontNormalBold}>Policy</Text> shall be valid
            until approved by one of Our executive officers and unless approval
            is endorsed hereon or attached hereto. No agent has authority to
            change this <Text style={styles.fontNormalBold}>Policy</Text> or
            waive any of its provisions.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>
            Assignment and Claims of Creditors
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            Benefits are not assignable except that an{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> may direct{' '}
            <Text style={styles.fontNormalBold}>Us</Text> to pay benefits to the{' '}
            <Text style={styles.fontNormalBold}>Veterinary Provider</Text> on
            whose charges any claim is based. Any such payment that{' '}
            <Text style={styles.fontNormalBold}>We</Text> make will fully
            discharge <Text style={styles.fontNormalBold}>Us</Text> to the
            extent of the payment.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Clerical Error</Text>
        </View>
        <View>
          <Text>
            Clerical error by <Text style={styles.fontNormalBold}>Us</Text>, the{' '}
            <Text style={styles.fontNormalBold}>Group</Text>, or their
            respective representatives will not:
          </Text>
        </View>
        <View style={[styles.positionParagraph, { marginLeft: '20px' }]}>
          <OrderedList
            points={[
              <Text>
                Cause a person to become insured under the{' '}
                <Text style={styles.fontNormalBold}>Group Policy</Text> or a
                provision of it,
              </Text>,
              <Text>Invalidate insurance otherwise validly in force,</Text>,
              <Text>Continue insurance otherwise validly terminated, or </Text>,
              <Text>
                Cause a <Text style={styles.fontNormalBold}>Group</Text> to
                obtain insurance under the{' '}
                <Text style={styles.fontNormalBold}>Group Policy</Text> or a
                provision of it.
              </Text>,
            ]}
            type="number"
          />
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            In the event that a clerical error results in an incorrect premium
            rate, <Text style={styles.fontNormalBold}>We</Text> may adjust the
            premium rate accordingly. The payment of premium, by itself, does
            not obligate <Text style={styles.fontNormalBold}>Us</Text> to
            provide benefits to anyone who is not eligible for insurance under
            the <Text style={styles.fontNormalBold}>Group Policy</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>
            Conformity with State and Federal Laws
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            If any provision of this{' '}
            <Text style={styles.fontNormalBold}>Policy</Text> is contrary to the
            applicable laws, <Text style={styles.fontNormalBold}>We</Text>{' '}
            hereby amend the provision to conform to the minimum requirements of
            the law.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Legal Actions</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            No action can be taken against{' '}
            <Text style={styles.fontNormalBold}>Us</Text> unless an{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> has complied with
            all of the terms and conditions of this{' '}
            <Text style={styles.fontNormalBold}>Policy</Text>, and ninety-one
            (91) days has elapsed after proof of loss is filed and the amount of
            loss is determined as provided in this{' '}
            <Text style={styles.fontNormalBold}>Policy</Text>. An{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> will have
            thirty-six (36) months from the date the claim is denied to take
            legal action against <Text style={styles.fontNormalBold}>Us</Text>{' '}
            with respect to recovery of a claim under this{' '}
            <Text style={styles.fontNormalBold}>Policy</Text>.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Paying Premiums</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            The <Text style={styles.fontNormalBold}>Group Policy</Text> does not
            become legally binding until{' '}
            <Text style={styles.fontNormalBold}>We</Text> have received the
            required premium.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Misstatement</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            If the enrollment information of the{' '}
            <Text style={styles.fontNormalBold}>Pet</Text> has been misstated,{' '}
            <Text style={styles.fontNormalBold}>We</Text> will make an equitable
            adjustment of premiums, benefits or both. The adjustment is based on
            the amount of insurance based on the correct enrollment information
            and the difference between the premiums paid or would have been paid
            if the enrollment information had been correctly stated.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>
            Time Limit on Certain Defenses
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Certificate Holder:</Text> Any
            statement made to obtain or to increase insurance, in the absence of
            fraud, is a representation and not a warranty. No misrepresentation
            will be used as a basis for reducing or denying a claim or
            contesting the validity of insurance unless{' '}
            <Text style={styles.fontNormalBold}>We </Text> have given the{' '}
            <Text style={styles.fontNormalBold}>Certificate Holder</Text> a copy
            of the written instrument he or she signed containing the
            misrepresentation.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            After insurance has been in effect for 2 years, during the lifetime
            of the <Text style={styles.fontNormalBold}>Pet, We</Text> will not
            use a misrepresentation as a basis for reducing or denying a claim.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>Group Policyholder</Text>: Any
            statement made by the{' '}
            <Text style={styles.fontNormalBold}>Group</Text> to obtain the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>, in the
            absence of fraud, is a representation and not a warranty. No
            misrepresentation by the{' '}
            <Text style={styles.fontNormalBold}>Group</Text> will be used as a
            basis for denying the validity of the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>, unless{' '}
            <Text style={styles.fontNormalBold}>We </Text>
            have given the <Text style={styles.fontNormalBold}>Group</Text> a
            copy of a written instrument signed by the{' '}
            <Text style={styles.fontNormalBold}>Group</Text>, containing the
            misrepresentation.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>We</Text> will not contest the
            validity of the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> after it has
            been in force for 2 years, except for nonpayment of premiums.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Other Insurance</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            If there is other pet insurance covering the same or comparable{' '}
            <Text style={styles.fontNormalBold}>Covered Expenses, We</Text> will
            pay only for the amount of{' '}
            <Text style={styles.fontNormalBold}>Covered Expenses</Text> in
            excess of the amount due from that other pet insurance, whether the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> can collect on it
            or not. Nevertheless, <Text style={styles.fontNormalBold}>We</Text>{' '}
            will not pay more than the applicable limits of insurance.
          </Text>
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '20px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>13</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View>
          <Text style={styles.fontNormalBold}>
            Period of Insurance and Territory
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            This <Text style={styles.fontNormalBold}>Coverage</Text> applies
            only to <Text style={styles.fontNormalBold}>Injuries</Text> and/or{' '}
            <Text style={styles.fontNormalBold}>Illnesses</Text> occurring
            during the{' '}
            <Text style={styles.fontNormalBold}>Coverage Period</Text> shown on
            the <Text style={styles.fontNormalBold}>Declarations Page</Text>{' '}
            anywhere in the United States and its territories, and{' '}
            <Text style={styles.fontNormalBold}>Emergency Treatment</Text> while
            traveling outside of the United States.{' '}
            <Text style={styles.fontNormalBold}>We</Text> will translate
            invoices and medical records in English, and convert the currency
            into U.S. dollars as of the date of Treatment.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Arbitration</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            It is hereby mutually agreed that any dispute or difference of
            agreement arising between{' '}
            <Text style={styles.fontNormalBold}>Us</Text> and the{' '}
            <Text style={styles.fontNormalBold}>
              Group Policyholder or Certificate Holder
            </Text>{' '}
            with respect to this agreement may be submitted to arbitration under
            rules of the American Arbitration Association (AAA). The place of
            Arbitration will be Massachusetts.
          </Text>
        </View>
        <View style={[styles.positionContentTitle, { marginTop: '25px' }]}>
          <Text style={styles.fontMediumBold}>Cancellation and Nonrenewal</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormalBold}>Insured</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            Except as otherwise provided for under this{' '}
            <Text style={styles.fontNormalBold}>Policy</Text>, insurance will
            cease on the earliest of the following to occur:
          </Text>
        </View>
        <View style={[styles.positionParagraph, { marginRight: '20px' }]}>
          <OrderedList
            points={[
              <Text>Upon an Insureds request;</Text>,
              <Text>
                The date the <Text style={styles.fontNormalBold}>Insured</Text>{' '}
                ceases to be an{' '}
                <Text style={styles.fontNormalBold}>Eligible Person</Text> or
                the premium due date on or next following the date the{' '}
                <Text style={styles.fontNormalBold}>Insured</Text> ceases to be
                an <Text style={styles.fontNormalBold}>Eligible Person</Text>.
              </Text>,
              <Text>
                The date the{' '}
                <Text style={styles.fontNormalBold}>Group Policy</Text>{' '}
                terminates; or
              </Text>,
              <Text>
                The date that <Text style={styles.fontNormalBold}>We</Text>{' '}
                determine fraudulent statements or material misrepresentation
                has been made by an{' '}
                <Text style={styles.fontNormalBold}>Insured</Text> or with the{' '}
                <Text style={styles.fontNormalBold}>Insured’s</Text> knowledge
                in filing a claim for benefits; or
              </Text>,
              <Text>The date the required premium payment is not paid.</Text>,
            ]}
            type="number"
          />
        </View>
        <View>
          <Text style={styles.fontNormalBold}>
            Termination or Amendment of the Group Policy
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            The <Text style={styles.fontNormalBold}>Group Policy</Text> may be
            terminated, changed or amended by{' '}
            <Text style={styles.fontNormalBold}>Us</Text> or the{' '}
            <Text style={styles.fontNormalBold}>Group</Text> according to the
            terms of the <Text style={styles.fontNormalBold}>Group Policy</Text>
            . Any change or amendment may apply to{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text> or to any
            separate classes or categories thereof. We may change the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> when any
            change or clarification in law or governmental regulation affects
            Our obligations under the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>, or with the{' '}
            <Text style={styles.fontNormalBold}>Group’s</Text> consent.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>We</Text> may terminate a{' '}
            <Text style={styles.fontNormalBold}>Group’s </Text>insurance on any
            premium due date by giving the{' '}
            <Text style={styles.fontNormalBold}>Group</Text> not less than a
            60-day notice. A <Text style={styles.fontNormalBold}>Group</Text>{' '}
            may terminate insurance under the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> in whole or
            may terminate insurance for any class or group of{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text>, at any time by
            giving <Text style={styles.fontNormalBold}>Us</Text> advanced
            written notice at least 60 days prior to termination. Insurance will
            terminate automatically for nonpayment of premium, subject to the{' '}
            <Text style={styles.fontNormalBold}>Group Policy’s</Text> grace
            period and reinstatement provisions.
          </Text>
        </View>
        <View style={[styles.positionContentTitle, { marginTop: '25px' }]}>
          <Text style={styles.fontMediumBold}>Claims Conditions</Text>
        </View>
        <View style={[{ marginRight: '20px' }]}>
          <OrderedList
            points={[
              <View>
                <Text>
                  In the event an{' '}
                  <Text style={styles.fontNormalBold}>Insured</Text> incurs a
                  loss they must notify{' '}
                  <Text style={styles.fontNormalBold}>Us</Text> by providing the
                  following:
                </Text>
                <OrderedList
                  points={[
                    <Text>
                      A completed claim form with{' '}
                      <Text style={styles.fontNormalBold}>Us</Text>, within
                      one-hundred and eighty (180) calendar days, or as soon as
                      practicable, of the date of{' '}
                      <Text style={styles.fontNormalBold}>Treatment</Text> or
                      veterinary services or date of receipt furnished to the{' '}
                      <Text style={styles.fontNormalBold}>Insured</Text> for
                      such <Text style={styles.fontNormalBold}>Treatment</Text>{' '}
                      or veterinary services, but in no event later than one
                      year after the date of{' '}
                      <Text style={styles.fontNormalBold}>Treatment</Text>.
                    </Text>,
                    <Text>
                      Invoices from the{' '}
                      <Text style={styles.fontNormalBold}>Insured’s</Text>{' '}
                      treating Veterinarian listing the services performed,
                      products provided and the itemized charges for{' '}
                      <Text style={styles.fontNormalBold}>Treatment</Text>,
                      including packages and/or discounts.
                    </Text>,
                    <Text>
                      A payment receipt when submitting a handwritten invoice.
                      If payment receipt is not provided the invoice will be
                      verified with the{' '}
                      <Text style={styles.fontNormalBold}>
                        Insured’s Veterinarian
                      </Text>{' '}
                      prior to claim processing
                    </Text>,
                  ]}
                  pointStyleObject={[{ marginRight: '20px' }]}
                  type="alpha"
                />
              </View>,
              <Text>
                <Text style={styles.fontNormalBold}>We</Text> reserve the right
                to ask for information from any{' '}
                <Text style={styles.fontNormalBold}>Veterinarian</Text> that has
                ever seen the{' '}
                <Text style={styles.fontNormalBold}>Insureds Pet(s)</Text> in
                order to assess its health.
              </Text>,
              <Text>
                <Text style={styles.fontNormalBold}>We</Text>, at Our expense,
                have the right to have any{' '}
                <Text style={styles.fontNormalBold}>Pet(s)</Text> examined by a{' '}
                <Text style={styles.fontNormalBold}>Veterinarian</Text> of{' '}
                <Text style={styles.fontNormalBold}>Our</Text> choice as often
                as reasonably necessary while a{' '}
                <Text style={styles.fontNormalBold}>Claim</Text> is pending.
              </Text>,
              <Text>
                If <Text style={styles.fontNormalBold}>We</Text> pay a claim
                contrary to this{' '}
                <Text style={styles.fontNormalBold}>Policy’s</Text> terms and
                conditions, that payment does not waive Our rights to apply
                those terms and conditions to any paid or any future claim.{' '}
                <Text style={styles.fontNormalBold}>We</Text> also have the
                right to stop payment or recover from the{' '}
                <Text style={styles.fontNormalBold}>Insured</Text> any claim
                amount paid incorrectly.
              </Text>,
              <Text>
                <Text style={styles.fontNormalBold}>The Insured</Text> must
                cooperate with <Text style={styles.fontNormalBold}>Us</Text> in
                the investigation or settlement of any{' '}
                <Text style={styles.fontNormalBold}>Claim</Text>.
              </Text>,
              <Text>
                Any <Text style={styles.fontNormalBold}>Illness or Injury</Text>{' '}
                where a final diagnosis has not been made will be pended as
                ineligible until<Text style={styles.fontNormalBold}> We</Text>{' '}
                receive written documentation from the{' '}
                <Text style={styles.fontNormalBold}>Insureds Veterinarian</Text>{' '}
                with the definitive diagnosis
              </Text>,
            ]}
            pointStyleObject={styles.positionParagraph}
            type="number"
          />
        </View>
        <View style={[styles.positionPage1Header]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>14</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View style={styles.positionContentTitle}>
          <Text style={styles.fontMediumBold}>Appeals and Complaints</Text>
        </View>
        <View>
          <Text style={styles.underLine}>Appeals</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            In the event an <Text style={styles.fontNormalBold}>Insured</Text>{' '}
            is not satisfied with the outcome of their{' '}
            <Text style={styles.fontNormalBold}>Claim</Text>, the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> may appeal. All
            requests for an appeal must be submitted to{' '}
            <Text style={styles.fontNormalBold}>Us</Text> within ninety (90)
            days, or as soon as reasonably practicable, of the date on their
            Explanation of Benefits, or as soon as reasonably practicable, on
            other actions giving rise to their complaint. Submit{' '}
            <Text style={styles.fontNormalBold}>Claims</Text> or appeals though
            the <Text style={styles.fontNormalBold}>Administrator’s</Text>{' '}
            secure portal or the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> may contact{' '}
            <Text style={styles.fontNormalBold}>Us</Text> using the information
            below.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            Send Correspondence to the{' '}
            <Text style={styles.fontNormalBold}>Administrator</Text> listed on
            the <Text style={styles.fontNormalBold}>Declarations Page</Text>.
          </Text>
        </View>
        <View>
          <Text style={styles.underLine}>Complaints</Text>
        </View>
        <View>
          <Text>
            If the <Text style={styles.fontNormalBold}>Insured</Text> disagrees
            with the decision made at any time during the appeal process, they
            have the right to file a complaint with their State Dpartment of
            Insurance. Please refer to the Individual State Department of
            Insurance for details and applicable rules and laws.
          </Text>
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '575px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL AI MA 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>15</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
  return (
    <GeneratedPDFViewer
      groupNumber={groupNumber}
      planChoiceId={planChoiceId}
      planId={planId}
      product={product}
    >
      {MAAMasterPolicy()}
    </GeneratedPDFViewer>
  );
};

MasterPolicyGenerator.propTypes = {
  address: PropTypes.shape({
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
  }).isRequired,
  coverageFromDate: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  groupNumber: PropTypes.string.isRequired,
  planChoiceId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  renewalDate: PropTypes.string.isRequired,
};

export default MasterPolicyGenerator;
